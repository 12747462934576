<template>
  <div class="story">
    <PozzyStory/>
  </div>
</template>

<script>
// @ is an alias to /src
import PozzyStory from '@/components/Story.vue'

export default {
  name: 'Story',
  components: {
    PozzyStory
  }
}
</script>
